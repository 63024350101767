<template>
	<div class="content">
		<h3 class="animated bounceInLeft first-title">
			"Don't let small minds convince you that your dreams are too big."</h3>
		<h3 class="animated flip second-title">To be a strong man!</h3>
		<img class="avarta" src="./../../assets/img/1.png"
:class="{animated: true, swing: isSwing}" @mouseover="over" @mouseout="move"
@mousedown="click">
		<h4>HOWIE-CH</h4>
		<div class="line"></div>
		<ContactMe ref="ContactMe"></ContactMe>
	</div>
</template>

<script>
	import ContactMe from './ContactMe.vue'

	export default {
		components: {
			ContactMe
		},
		data() {
			return {
				isSwing: false,
			}
		},
		mounted () {
			setTimeout( ()=> {
				this.over()
			}, 1500)
		},
		methods: {
			over() {
				this.isSwing = true;
				console.log(this.$refs.ContactMe);
				this.$refs.ContactMe.startAnimatedContact();
			},
			move() {
				this.isSwing = false;
				this.$refs.ContactMe.stopAnimatedContact();
			},
			click() {
				window.location.href = 'about.html';
				// this.$message('待开发');
				// this.$router.push({ path: '/about'});
			}
		}
	}
</script>

<style lang="scss" scoped>

	@import "./../../assets/css/base";

	.content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;


		.first-title {
			width: 50%;
			/*font-size: px2rem(20);*/
		}

		.second-title {
			width: 50%;
			margin-top: 15px;
			margin-left: auto;
			margin-right: auto;
			/*font-size: px2rem(20);*/
		}

		.avarta {
			margin-top: 10px;
			width: 88px;
			height: 88px;
			border-radius: 50%;
			border-width: 5px;
			border-style: solid;
			border-color: rgba(255, 255, 255, 0.2);
		}

		.line {
			width: 150px;
			height: 1px;
			margin: 20px auto;
			padding: 0px;
			background-color: rgba(115, 115, 115, 0.5);
			overflow: hidden;
		}
	}

	h4 {
		margin: 20px 0;
		font-size: 14px;
	}

	h3 {
		margin: 20px 0;
		font-size: 18px;
	}
</style>

