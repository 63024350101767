<template>
  <div class="copyright">
    <img class="left-collect"
         src="./../../assets/img/collect.png" alt="" @click="clickCollection">
    <i>Copyright &copy; 2017 HOWIE-CH</i>
    <img
        class="right-enter-manage" src="./../../assets/img/enter.png" alt=""
        @click="clickManage">
  </div>
</template>

<script>

  import { mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters(['getAuth']),
    },
    methods: {
      clickManage() {
        let path = '/login'
        if (!this.$utils.isEmpty(this.getAuth)) {
          path = '/manage'
        }
        this.$router.push({path: path})
            .catch((err) => {
              console.log(err);
            });
      },
      clickCollection() {
        this.$message('待开发');
      }
    }
  }
</script>

<style scoped>

  @import "../../ui/ui.element.reset.css";

  .copyright {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }


  img {
    width: 20px;
    height: 20px;
  }

  .left-collect {
    margin-left: 10px;
  }

  .right-enter-manage {
    margin-right: 10px;
  }

  i {
    font-size: 13px;
    color: darkgrey;
    display: inline-block;
  }
</style>
