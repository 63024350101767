<template>
	<div class="contactme">
			<div class="item" v-for="(item, index) in contactData" :key="index">
				<img :class="{animated: true, shake: item.isBounceIn}" :src="item.favicon" :alt="item.alt" @mouseover="contactOver(index)" @mouseout="contactMove(index)" @click="click(item.url)">
			</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				contactData: [{
					favicon: require('./../../assets/img/cnblog.png'),
					alt: '博客园',
					url: 'http://www.cnblogs.com/howie-ch/',
					isBounceIn: false
				}, {
					favicon: require('./../../assets/img/github.png'),
					alt: 'GitHub',
					url: 'https://github.com/HOWIE-CH/',
					isBounceIn: false
				}, {
					favicon: require('./../../assets/img/163mail.png'),
					alt: '邮箱xxxx',
					url: 'mailto:zhanghaocoder@163.com',
					isBounceIn: false
				}]
			}
		},
		methods: {
			contactOver(index) {
				const item = this.contactData[index];
				item.isBounceIn = true;
			},
			contactMove(index) {
				const item = this.contactData[index];
				item.isBounceIn = false;
			},
			startAnimatedContact() {
				// eslint-disable-next-line no-unused-vars
				this.contactData.forEach((item, index) => {
					item.isBounceIn = true;
				});
			},
			stopAnimatedContact() {
				// eslint-disable-next-line no-unused-vars
				this.contactData.forEach((item, index) => {
					item.isBounceIn = false;
				});
			},
			click(url) {
				window.location.href = url;
			}
		}

	}
</script>

<style lang="scss" scoped>
	.contactme {
		height: 40px;
		width: 120px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
	.item {
		line-height: 16px;
	}

</style>

