<template>
	<div class="content">
		<MovieBg class="movie-bg"></MovieBg>
		<Introduction class="introduction"></Introduction>
		<Copyright class="copyright"></Copyright>
	</div>
</template>

<script>
	import MovieBg from '../../components/home/MovieBg.vue'
	import Introduction from '../../components/home/Introduction.vue'
	import Copyright from '../../components/home/Copyright.vue'

	export default {
		components: {
			MovieBg,
			Introduction,
			Copyright
		}
	}
</script>

<style lang="scss" scoped>
	.content {
		width: 100%;
		height: 100%;
		position: relative;
		overflow: hidden;
	}
	.movie-bg {
		z-index: -1;
		position: absolute;
		width: 100%;
		height: 100%;
	}
	.introduction {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-32%);
		width: 100%;
		text-align: center;
		color: white;
	}
	.copyright {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 5px;

	}
</style>

