<template>
	<div class="homepage-hero-module">
		<div class="video-container">
			<!--			<div class="filter"></div>-->
			<div class="poster hidden">
				<img src="../../assets/img/For_Wes.jpg" alt="">
			</div>
			<video muted autoplay loop class="fillWidth">
				<source src="../../assets/img/For_Wes.mp4" type="video/mp4"/>
				<source src="../../assets/img/For_Wes.webm" type="video/webm"/>
			</video>
		</div>
	</div>
</template>

<script>
	import $ from  'jquery';
	export default {
		methods: {
			scaleVideoContainer() {
				var height = $(window).height() + 5;
				var unitHeight = parseInt(height) + 'px';
				$('.homepage-hero-module').css('height', unitHeight);
			},
			initBannerVideoSize(element) {
				$(element).each(function () {
					$(this).data('height', $(this).height());
					$(this).data('width', $(this).width());
				});
				this.scaleBannerVideoSize(element);
			},
			scaleBannerVideoSize(element) {
				var windowWidth = $(window).width(),
					windowHeight = $(window).height() + 5,
					videoWidth,
					videoHeight;
				// console.log(windowHeight);
				$(element).each(function () {
					var videoAspectRatio = $(this).data('height') / $(this).data('width');
					$(this).width(windowWidth);
					if (windowWidth < 1000) {
						videoHeight = windowHeight;
						videoWidth = videoHeight / videoAspectRatio;
						$(this).css({
							'margin-top': 0,
							'margin-left': -(videoWidth - windowWidth) / 2 + 'px'
						});
						$(this).width(videoWidth).height(videoHeight);
					}
					$('.homepage-hero-module .video-container video').addClass('fadeIn animated');
				});
			}
		},
		mounted() {
			this.scaleVideoContainer();
			// this.initBannerVideoSize('.video-container .poster img');
			// this.initBannerVideoSize('.video-container .filter');
			this.initBannerVideoSize('.video-container video');
		}
	}
</script>

<style scoped>

	.no-video .video-container video,
	.touch .video-container video {
		display: none;
	}

	.no-video .video-container .poster,
	.touch .video-container .poster {
		display: block !important;
	}

	.video-container {
		position: relative;
		bottom: 0%;
		left: 0%;
		height: 100%;
		width: 100%;
		overflow: hidden;
		background: #000;
	}

	.video-container .poster img {
		height: 100%;
		position: absolute;
		left: 50%;
		transform: translateX(-50%)
	}

	/*.video-container .filter {*/
	/*	z-index: 100;*/
	/*	position: absolute;*/
	/*	background: rgba(0, 0, 0, 0.4);*/
	/*	width: 100%;*/
	/*}*/

	.video-container video {
		position: absolute;
		z-index: 0;
		bottom: 0;
	}

	.video-container video.fillWidth {
		width: 100%;
	}
</style>

